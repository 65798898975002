import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export type LoginStatus = 'auth' | 'guest';
export type PageType =
  | 'login'
  | 'application_process'
  | 'unsubscribe'
  | 'error';

declare global {
  interface Window {
    digitalData: {
      page: {
        attributes: {
          campaignName?: string;
          agencyName?: string;
        };
        siteInfo: {
          country?: string;
          version?: string;
          currency?: string;
        };
        pageInfo: {
          language?: string;
          pageName?: string;
          pageURL?: string;
          channel?: string;
          partner?: string;
          previousPageName?: string;
          pageType?: PageType;
          deploymentChannel?: string;
          productReleaseVersion?: string;
          errorName?: string;
          errorID?: string;
        };
      };
      user: {
        profile: {
          profileInfo: {
            loginStatus?: LoginStatus;
          };
        };
      };
    };
  }
}

export const Analytics = () => {
  const location = useLocation();
  const { search } = useLocation();
  const projectNameFromLocation = location.pathname.split('/')[1];
  init(projectNameFromLocation);

  const urlSearchParams = new URLSearchParams(search);
  useEffect(() => {
    window.digitalData.page.pageInfo.pageURL = window.location.href;
    window.digitalData.user.profile.profileInfo.loginStatus =
      urlSearchParams.has('code') ||
      window.sessionStorage.getItem('isLoggedIn') === 'true'
        ? 'auth'
        : 'guest';
  }, [location]);

  useEffect(() => {
    /*    const trackingScriptReady = () => {
      console.log('TRACKING track by ready event: ', window.digitalData);
      window._satellite?.track('pageload');
    };
    window.addEventListener(
      TRACKING_SCRIPT_READY_EVENT_NAME,
      trackingScriptReady
    );
    return () =>
      window.removeEventListener(
        TRACKING_SCRIPT_READY_EVENT_NAME,
        trackingScriptReady
      );*/
  }, []);
  return <></>;
};

export const updatePageName = (
  pageName?: string,
  pageType?: PageType,
  errorID?: string,
  errorName?: string,
  loginStatus?: LoginStatus,
  previousPageName?: string
): void => {
  const pageInfo = window.digitalData.page.pageInfo;

  if (previousPageName) {
    pageInfo.previousPageName = previousPageName;
  } else {
    pageInfo.previousPageName =
      pageInfo.pageName !== pageName
        ? pageInfo.pageName
        : pageInfo.previousPageName;
  }

  window.digitalData.page.pageInfo = {
    ...pageInfo,
    pageName: pageName,
    pageType: pageType,
    errorID: errorID,
    errorName: errorName,
  };

  if (loginStatus)
    window.digitalData.user.profile.profileInfo.loginStatus = loginStatus;
};

const getCampaignPrefix = (project: string) => {
  if (project.toLowerCase() === 'amex') return 'Amex_Antragsstrecke';
  else if (project.toLowerCase() === 'amexv2') return 'antragsstrecke_redesign';
  else if (project.toLowerCase() === 'visa') return 'Visa_Antragsstrecke';
  else return project + '_Antragsstrecke';
};

const init = (project: string) => {
  window.digitalData = {
    page: {
      attributes: {
        campaignName: project ? getCampaignPrefix(project) : undefined,
        agencyName: 'syngenio',
      },
      siteInfo: {
        country: 'DE',
        version: '1.00',
        currency: 'EUR',
      },
      pageInfo: {
        language: 'de',
        pageName: undefined,
        pageURL: window.location.href,
        channel: project,
        partner: project,
        previousPageName: undefined,
        pageType: undefined,
        deploymentChannel: 'partner_syngenio',
        productReleaseVersion: '1.0',
        errorName: undefined,
        errorID: undefined,
      },
    },
    user: {
      profile: {
        profileInfo: {
          loginStatus: 'guest',
        },
      },
    },
  };
};
