export const getMuiStyleOverrides = () => ({
  components: {
    MuiSelect: {
      styleOverrides: {
        select: {
          whiteSpace: 'nowrap',
          '@media (min-width: 600px)': {
            whiteSpace: 'normal',
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          whiteSpace: 'normal',
        },
      },
    },
    MuiStep: {
      styleOverrides: {
        root: {
          paddingLeft: 0,
          paddingRight: 0,
        },
      },
    },
  },
});
