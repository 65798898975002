import { constants } from '../../constants';

export const DOCUMENT_TITLE = {
  PAYBACK_DEFAULT: 'PAYBACK',
  PAYBACK_AMEX: 'PAYBACK American Express | kostenlose Kreditkarte | PAYBACK',
  PAYBACK_VISA: 'PAYBACK Visa | kostenlose Kreditkarte | PAYBACK',
  SYNGENIO_DEFAULT: 'Agile Forms by Syngenio',
};

export const getDocumentTitle = (project?: string, documentTitle?: string) => {
  if (documentTitle?.trim()) {
    return documentTitle.trim();
  }

  // fallback document titles for PAYBACK if not set in the editor
  if (constants.PATH_FOR_CUSTOM_STATIC_PAGES === 'payback' && project) {
    switch (project) {
      case 'amex':
      case 'amexVers':
      case 'amexv2':
        return DOCUMENT_TITLE.PAYBACK_AMEX;
      case 'visa':
        return DOCUMENT_TITLE.PAYBACK_VISA;
      default:
        return DOCUMENT_TITLE.PAYBACK_DEFAULT;
    }
  }

  return DOCUMENT_TITLE.SYNGENIO_DEFAULT;
};
