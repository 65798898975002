import React, { createContext, useContext } from 'react';
import { getDocumentTitle } from '../services/metadataUtils';

interface Metadata {
  documentTitle?: string;
  lang?: string;
}

const defaultMetadata: Metadata = {
  documentTitle: 'Agile Forms by Syngenio',
  lang: 'de',
};

const MetadataContext = createContext<Metadata>(defaultMetadata);

export const useMetadata = () => useContext(MetadataContext);

interface MetadataProviderProps {
  metadata: Metadata;
  project?: string;
  children: React.ReactNode;
}

export const MetadataProvider: React.FC<MetadataProviderProps> = ({
  metadata,
  project,
  children,
}) => {
  const actualMetadata: Metadata = {
    documentTitle: getDocumentTitle(project, metadata.documentTitle),
    lang: metadata.lang || defaultMetadata.lang,
  };

  return (
    <MetadataContext.Provider value={actualMetadata}>
      {children}
    </MetadataContext.Provider>
  );
};
